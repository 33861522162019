Dashboard.css/* frontend/src/components/Dashboard.css */

/* Set background color to white and make the dashboard fill the entire viewport */
.dashboard {
  background-color: white;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: relative; /* Allows absolute positioning of children */
}

/* Styling for the header section containing the logo and title */
.dashboard-header {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  padding: 10px; /* Add padding inside the header */
  position: absolute; /* Position absolutely within the .dashboard */
  top: 10px; /* Position 10px from the top of .dashboard */
  left: 10px; /* Position 10px from the left of .dashboard */
}

/* Styling for the title ("The Enterprise") */
.dashboard-header h1 {
  color: black; /* Set text color to black */
  margin: 0; /* Remove margin */

}

/* Styling for the logo */
.dashboard-logo {
  height: 60px; /* Adjust the height of the logo */
  margin-right: 10px; /* Add margin to the right of the logo */
}

/* Styling for the horizontal line */
.dashboard-line {
  border: none; /* Remove border */
  border-top: 2px solid black; /* Add 2px solid black border on top */
  width: 100%; /* Set width to 100% of the .dashboard */
  position: absolute; /* Position absolutely within the .dashboard */
  top: calc(10px + 60px); /* Position 10px + 60px from the top of .dashboard (adjust as needed) */
  left: 0; /* Position 0px from the left of .dashboard */
}
