/* Modal container and background overlay */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5); /* Black background with transparency */
}

.modal.open {
  display: block; /* Display modal when open */
}

/* Modal content box */
.modal-content {
  background-color: #fff;
  margin: 10% auto; /* Center the modal */
  padding: 20px;
  border-radius: 8px;
  width: 400px; /* Adjust modal width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  position: relative; /* Allows positioning of close button */
}

/* Close button styling */
.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Form input styles */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: calc(100% - 20px);
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group select {
  padding: 8px;
}

/* Save button styling */
.save-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 100%; /* Full width button */
}

.save-button:hover {
  background-color: #0056b3;
}

/* Notification box styling */
.notification {
  margin-top: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border-left: 4px solid green;
  color: black;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification.error {
  border-left-color: red;
}

.close-notification {
  cursor: pointer;
  font-weight: bold;
}
