/* Modal container and background overlay */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5); /* Black background with transparency */
}

.modal.open {
  display: block; /* Display modal when open */
}

/* Modal content box */
.modal-content {
  background-color: #fff;
  margin: 5% auto; /* Center the modal */
  padding: 20px;
  border-radius: 8px;
  width: 95%; /* Increase modal width for larger tables */
  max-width: 1400px; /* Set a larger maximum width to fit more fields */
  max-height: 90%; /* Allow modal to use up to 90% of the screen height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  position: relative; /* Allows positioning of close button */
  overflow-y: auto; /* Enable scrolling if the modal content is too tall */
}

/* Close button styling */
.close-button {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Tab buttons styling */
.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  flex: 1;
  font-size: 16px;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-button:not(.active):hover {
  background-color: #ddd;
}

/* Table styling */
.transactions-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.transactions-table th,
.transactions-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  word-wrap: break-word; /* Ensure long text does not overflow */
}

.transactions-table th {
  background-color: #f4f4f4;
}

.transactions-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transactions-table tr.highlighted {
  background-color: #ffeb3b;
}

.transactions-table tr:hover {
  background-color: #ddd;
}

/* Input field styling */
input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Action buttons */
.pending-transaction-actions button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.pending-transaction-actions button:hover {
  background-color: #0056b3;
}

/* Highlight message and result */
.check-result {
  color: green;
  margin-top: 10px;
}

.check-result.error {
  color: red;
}

/* Add some padding to the modal content */
.tab-content {
  padding-top: 20px;
}

/* Notifications styling (same as the other modals) */
.notification {
  margin-top: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border-left: 4px solid green;
  color: black;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification.error {
  border-left-color: red;
}

.close-notification {
  cursor: pointer;
  font-weight: bold;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters select {
  padding: 10px;
  width: 48%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.refresh-button {
  padding: 8px 16px;
  margin-left: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.refresh-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}