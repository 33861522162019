/* Overall Dashboard Layout */
.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.dashboard-logo {
  height: 50px;
}

.dashboard-logo-link,
.dashboard-header-link {
  text-decoration: none;
  color: inherit;
}

.dashboard-header-link h1 {
  margin: 0;
}

.dashboard-nav {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.nav-button {
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.nav-button:hover {
  background-color: #0056b3;
}

.dashboard-line {
  border: 1px solid #ddd;
  margin: 15px 0;
}

/* Dashboard Content */
.dashboard-content {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin-top: 120px;
}

/* Large Table Container */
.large-table-container {
  flex: 2;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  overflow-x: auto;
  width: 100%;
  max-width: 1100px;
  margin-top: 0px;
}

/* Weekly Tasks Table */
.large-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  table-layout: fixed !important;
}

.large-table th,
.large-table td {
  padding: 14px;
  border: 1px solid #ddd;
  text-align: center;
}

/* Table Header Styling */
.large-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 16px;
}

/* Table Cell Styling */
.large-table td {
  background-color: #f9f9f9;
}

/* Add Row Icon */
.add-row-icon {
  cursor: pointer;
  margin-left: 8px;
  color: white;
  font-size: 16px;
  vertical-align: middle;
}

.add-row-icon:hover {
  color: #ddd;
}

/* Edit & Save Icons */
.edit-icon,
.edit-save-icon {
  cursor: pointer;
  margin-left: 8px;
  color: #007bff;
  font-size: 16px;
  vertical-align: middle;
}

.edit-icon:hover,
.edit-save-icon:hover {
  color: #0056b3;
}

/* Task Done Icon */
.task-done {
  font-size: 22px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.task-done:hover {
  opacity: 0.7;
}

/* Input & Select Styling */
.large-table input,
.large-table select {
  width: 95%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}

/* Small Table Container */
.small-tables-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 400px;
  margin-top: 0px;
}

/* Small Table Styling */
.small-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.small-table th,
.small-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.small-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.small-table td {
  background-color: #f9f9f9;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .dashboard-content {
    flex-direction: column;
  }

  .large-table-container,
  .small-tables-container {
    max-width: 100%;
    overflow-x: auto;
  }

  .large-table th,
  .large-table td,
  .small-table th,
  .small-table td {
    font-size: 14px;
    padding: 10px;
  }
}
