html, body {
  background-color: white;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.dashboard {
  background-color: white;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow-y: auto; /* Allow vertical scrolling if needed */
}

/* Fixed Header */
.dashboard-header {
  display: flex;
  align-items: center;
  position: fixed; /* Keep the header fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dashboard-header h1 {
  color: black;
  margin: 0;
}

.dashboard-logo {
  height: 60px;
}

/* Fixed Filter Checkbox on the Left */
.fixed-filter {
  position: fixed;
  top: 80px;
  left: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  gap: 5px;
}

.fixed-filter input[type="checkbox"] {
  margin-right: 5px;
}

/* Navigation Bar Styling */
.dashboard-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: transparent;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 10px;
}

.nav-button {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  white-space: nowrap;
}

.nav-button:hover {
  background-color: #0056b3;
}

.update-all-button, .add-wallet-button {
  position: fixed;
  top: 80px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  align-self: flex-end;
  width: 130px;
  z-index: 9;
}

.update-all-button {
  right: 10px;
}

.add-wallet-button {
  right: 150px;
}

.add-wallet-button:hover,
.update-all-button:hover {
  background-color: #0056b3;
}

.wallets-container {
  margin-top: 180px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.wallets-table {
  width: 90%;
  border-collapse: collapse;
  margin-top: 20px;
}

.wallets-table th,
.wallets-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.wallets-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 12px;
}

.wallets-table tr:nth-child(odd) {
  background-color: white;
}

.wallets-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.wallets-table tr:hover {
  background-color: #ddd;
}

.wallets-table td button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.wallets-table td button:hover {
  background-color: #0056b3;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.total-balance-container-discreet {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  padding: 5px 20px;
  font-size: 0.9rem;
  color: #666;
}

.total-balance-amount-discreet {
  font-weight: 500;
  color: #333;
}
