Dashboard.css/* frontend/src/components/Dashboard.css */

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-container {
  position: absolute;
  top: 130px; /* Position 40px below the dashboard line */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th,
.transactions-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.transactions-tabletable th {
  background-color: #f2f2f2;
  text-align: left;
}

.transactions-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transactions-table tr:hover {
  background-color: #ddd;
}



.update-transaction-button {
  position: absolute;
  top: 95px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  gap: 10px; /* Space between the buttons */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 0;
  align-self: flex-end; /* Align buttons to the right within the flex container */
  width: 160px; /* Adjust width as needed */
  margin-right: 10px; /* Add margin to separate from the next button */
}

.add-transaction-button {
  position: absolute;
  top: 95px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 0;
  align-self: flex-end; /* Align buttons to the right within the flex container */
  width: 160px; /* Adjust width as needed */
  margin-right: 180px; /* Add margin to separate from the next button */

}

.add-transaction-button:hover,
.update-transaction-button:hover {
  background-color: #0056b3;
}

/* Styling for the filter section */
.filter-section {
  position: absolute;
  top: 120px; /* Adjust top position as needed */
  margin-left: 20px; /* Adjust left position as needed */
}

.filter-section label {
  display: flex;
  align-items: center;
}

.filter-section input[type="checkbox"] {
  margin-right: 8px;
}