.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.account-form {
    display: flex;
    flex-direction: column;
}

.account-form label {
    margin-bottom: 10px;
    font-weight: bold;
}

.account-form input {
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.save-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.save-button:hover {
    background-color: #0056b3;
}
.account-input {
  width: 100%;
  padding: 0.5rem;
  /* ensure we set an explicit color and background */
  color: #000;                /* text color, black */
  background-color: #fff;     /* background, white */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.account-input option {
  color: #000;                /* ensure option text is visible */
}


