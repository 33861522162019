/* frontend/src/components/TodaysBetsTable.css */

.dashboard-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.dashboard-section h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 16px;
  text-align: left;
}

.bets-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f9f9f9;
  font-size: 1rem;
}

.bets-table th,
.bets-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
}

.bets-table th {
  background-color: #007bff;
  color: #fff;
  font-weight: 600;
}

.match-row {
  cursor: pointer;
  background-color: #e9ecef;
  font-weight: bold;
}

.match-row:hover {
  background-color: #d6d8db;
}

.expand-label {
  text-align: right;
  font-size: 0.9rem;
  color: #007bff;
  cursor: pointer;
}

.bet-details td {
  background-color: #f2f2f2;
  text-align: left;
  padding-left: 20px; /* Indent expanded rows */
}

.bets-table tr:nth-child(even) .bet-details {
  background-color: #f9f9f9;
}

.bets-table td[colspan='3'] {
  text-align: center;
  font-style: italic;
  color: #777;
}
/* Example in Bets.css */

/* Container for the tab buttons */
.bets-tabs {
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;            /* Place tabs horizontally */
  gap: 8px;                 /* Spacing between buttons */
}

/* Style for each tab button */
.bets-tab-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #eee;   /* Light gray background */
  cursor: pointer;
  font-size: 1rem;
}

/* Hover effect for tab buttons */
.bets-tab-button:hover {
  background-color: #ddd;
}

/* Active tab styling */
.bets-tab-button.active {
  background-color: #007bff;  /* Blue background */
  color: #fff;                /* White text */
}

.bets-table th,
.bets-table td {
  text-align: center;
}

.stake-button {
  display: block;
  margin: 0 auto;
}