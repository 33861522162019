

/* Filters and Add Account button container */
.filters-add-bookmaker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px 0 20px 0; /* Adjusted the top margin to move it 10px down */
}

/* Filters section */
.filters-container {
  display: flex;
  gap: 20px;
}

.filter label {
  margin-right: 10px;
  font-weight: bold;
}

.filter select {
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Add Account button */
.add-bookmaker-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.add-bookmaker-button:hover {
  background-color: #0056b3;
}

/* Accounts table styling */
.bookmaker-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.bookmaker-table th,
.bookmaker-table td {
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
  text-align: center;
}

.bookmaker-table th {
  background-color: #f4f4f4;

}

.bookmaker-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.bookmaker-table tr:hover {
  background-color: #f1f1f1;
}

/* Button for editing status */
.status-edit-button,
.status-save-button {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
}

.status-save-button {
  background-color: #007bff;
}

.status-edit-button:hover,
.status-save-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .filters-add-bookmaker-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters-container {
    flex-direction: column;
    gap: 10px;
  }

  .add-bookmaker-button {
    margin-top: 10px;
  }
}

.search-input {
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.sort-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
}
.edit-icon {
  margin-left: 8px;
  cursor: pointer;
  color: #007bff;
}

.edit-icon:hover {
  color: #0056b3;
}

