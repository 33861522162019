/* frontend/src/components/Login.css */

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black; /* Ensure background color is black */
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the container covers the full viewport height */
  width: 100vw;
  background-color: black; /* Ensure background is black */
}

.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/EnterpriseLogo.png') no-repeat center center;
  background-size: 30%; /* Adjust the size of the logo */
  opacity: 0.9; /* Optional: Slight transparency if needed */
  z-index: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 300px;
  text-align: center;
  position: relative; /* Change from absolute */
  z-index: 1; /* Ensure form is above the background */
  margin-top: 700px;
}

.login-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.login-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid white;
  border-radius: 8px;
  background-color: white;
  color: black;
  font-size: 16px;
}

.login-form input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.login-form input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.login-form button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 25px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.login-form button:hover {
  background-color: #218838;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px);
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
