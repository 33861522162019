html, body {
  background-color: white;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.dashboard {
  position: relative;
  padding: 20px;
  margin-bottom: 0px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.dashboard-logo {
  width: 50px;
  height: auto;
  margin-right: 15px;
}



/* Ensure that the page remains responsive */
@media (max-width: 768px) {

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.dashboard-line {
  margin-top: 20px;
  border: none;
  border-top: 1px solid #ccc;
}

/* Filters and Add Account button container */
.filters-add-account-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px 0 20px 0; /* Adjusted the top margin to move it 10px down */
}

/* Filters section */
.filters-container {
  display: flex;
  gap: 20px;
}

.filter label {
  margin-right: 10px;
  font-weight: bold;
}

.filter select {
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Add Account button */
.add-account-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.add-account-button:hover {
  background-color: #0056b3;
}

/* Accounts table styling */
.accounts-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.accounts-table th,
.accounts-table td {
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
}

.accounts-table th {
  background-color: #f4f4f4;
  text-align: left;
}

.accounts-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.accounts-table tr:hover {
  background-color: #f1f1f1;
}

/* Button for editing status */
.status-edit-button,
.status-save-button {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
}

.status-save-button {
  background-color: #007bff;
}

.status-edit-button:hover,
.status-save-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .filters-add-account-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters-container {
    flex-direction: column;
    gap: 10px;
  }

  .add-account-button {
    margin-top: 10px;
  }
}
