/* WalletModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.wallet-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.wallet-form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.save-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.save-button:hover {
    background-color: #0056b3;
}

.notification {
    margin-top: 15px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    text-align: center;
    border-radius: 5px;
}

.close-notification {
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/* Ensure the logo remains separate */
.dashboard-logo {
  height: 50px;
  margin-right: 10px;
}

/* Make the entire header text clickable */
.dashboard-header-link {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  /* Optional: Add some padding to enlarge the clickable area */
  padding: 5px 10px;
}

/* Ensure the header text fills the link area */
.dashboard-header-text {
  margin: 0;
  cursor: pointer;
  display: block; /* Makes it take up the full width of the link */
}
